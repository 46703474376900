import styled from "@styles/themedComponents";

export const GalleryWidgetStyle = styled.div`
  padding: 0 15px;
  margin-left: -3px;
  margin-bottom: -3px;

  .widget-product {
    display: inline-block;
    width: calc((100% / 3) - 3px);
    line-height: 0;
    margin-left: 3px;
    margin-bottom: 3px;
  }
`;
