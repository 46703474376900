import { WIDGET_LAYOUT_TYPE } from "@amondz/constants";
import styled from "@styles/themedComponents";

interface IGridWidgetStyleProps {
  layoutType: number;
}
export const GridWidgetStyle = styled.div<IGridWidgetStyleProps>`
  display: grid;
  grid-template-columns: ${({ layoutType }) =>
    layoutType === WIDGET_LAYOUT_TYPE.BRAND.GRID._1x4 ? "repeat(4, 1fr)" : "repeat(2, 1fr)"};
  grid-gap: ${({ layoutType }) => (layoutType === WIDGET_LAYOUT_TYPE.BRAND.GRID._1x4 ? "7px" : "15px")};
  row-gap: 20px;
  padding: 0 24px;

  strong {
    ${({ layoutType }) =>
      layoutType === WIDGET_LAYOUT_TYPE.BRAND.GRID._1x4 &&
      `
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.3px;
      text-align: center;
    `}
  }

  ${(props) => props.theme.media.phone`
    padding: 0 15px;
  `};
`;
