import styled from "@styles/themedComponents";

export const SwipeWidgetStyle = styled.div`
  .widget-top-image {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    line-height: 0;
  }
  .widget-product-wrapper {
    display: flex;
    gap: 15px;
    padding-left: 15px;
    padding-right: 15px;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;

    // 스크롤바 숨기기
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }

    & > a {
      min-width: 30%;
    }

    .widget-product {
      &:not(:first-of-type) {
        margin-left: 15px;
      }
    }

    ${(props) => props.theme.media.phone`
		& > a  {
		    min-width:auto;
		}
		.widget-product {
            &.small {
                width: 140px;
            }

            &.big {
              width: 221px;
            }
        }
	`}
  }
`;
