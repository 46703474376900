import styled from "@styles/themedComponents";

interface IIndicatorWidgetStyleProps {
  margin: boolean;
}
export const IndicatorWidgetStyle = styled.div<IIndicatorWidgetStyleProps>`
  .swiper-pagination-bullets {
    bottom: 14%;
  }

  .swiper {
    border-radius: ${(props) => (!props.margin ? 0 : "2px")};
    overflow: hidden;
  }

  ${(props) => props.margin && "padding: 0 15px"};
`;
