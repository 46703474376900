import * as React from "react";
import { FC, useState } from "react";
import Link from "next/link";
import { BannerStateType } from "@amondz/types";
import { WIDGET_LAYOUT_TYPE } from "@amondz/constants";
import Img from "@components/common/Img/Img";
import { getUrlToLinkType } from "@lib/utility/linkType";
import {
  BANNER_SWIPE_MINIMUM_NUMBER,
  MOBILE_EVENT_AND_BANNER_KEY,
  PC_EVENT_AND_BANNER_KEY,
} from "@constants/service/home/home";
import { INTRO_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import { SwipeWidgetStyle } from "./style";
import Slider from "../../../../../../components/common/Slider";

interface ISwipeWidgetProps {
  itemList: BannerStateType[];
  imageTarget: typeof PC_EVENT_AND_BANNER_KEY | typeof MOBILE_EVENT_AND_BANNER_KEY;
  layoutType: number;
  onClickWidgetItem: (param: { index: number; item: BannerStateType }) => void;
}

const SwipeWidget: FC<ISwipeWidgetProps> = (props) => {
  const { itemList, layoutType, imageTarget, onClickWidgetItem } = props;
  const size = layoutType === WIDGET_LAYOUT_TYPE.BANNER.SWIPE_BIG ? "big" : "small";
  const [isEdge, setEdge] = useState(true);

  const onSlideChange = (activeIndex: number): void => {
    if (activeIndex === 0) {
      setEdge(true);
    } else if (size === "big" && activeIndex === itemList.length - 2) {
      setEdge(true);
    } else if (size === "small" && activeIndex === itemList.length - 3) {
      setEdge(true);
    } else {
      setEdge(false);
    }
  };

  return (
    <SwipeWidgetStyle size={size} swipe={itemList.length <= BANNER_SWIPE_MINIMUM_NUMBER[size]} edge={isEdge}>
      <Slider slidesPerView="auto" spaceBetween={10} onSlideChange={onSlideChange}>
        {itemList.map((item, index) => {
          return (
            <Link
              key={index}
              href={getUrlToLinkType(item.linkType, item.linkUrl, item.id) || INTRO_PAGE_URL_PATH}
              onClick={() => onClickWidgetItem({ index, item })}
            >
              <Img
                src={item[imageTarget]?.imgUrl}
                width={item[imageTarget]?.width}
                height={item[imageTarget]?.height}
                layout="responsive"
              />
            </Link>
          );
        })}
      </Slider>
    </SwipeWidgetStyle>
  );
};

export default SwipeWidget;
