import * as React from "react";
import { FC } from "react";
import Link from "next/link";
import { BrandBaseWidgetItemType, BrandGridWidgetItemType } from "@amondz/types";
import { WIDGET_LAYOUT_TYPE } from "@amondz/constants";
import BaseCard from "@components/common/BaseCard";
import { BRAND_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import { GridWidgetStyle } from "./style";

interface IGridWidgetProps {
  itemList: BrandGridWidgetItemType[];
  layoutType: number;
  onClickWidgetItem: <Item extends BrandBaseWidgetItemType>(param: { index: number; item: Item }) => void;
}

const GridWidget: FC<IGridWidgetProps> = (props) => {
  const { itemList, layoutType, onClickWidgetItem } = props;

  return (
    <GridWidgetStyle layoutType={layoutType}>
      {itemList.map((item, index) => (
        <Link key={index} href={`${BRAND_PAGE_URL_PATH}/${item.id}`} onClick={() => onClickWidgetItem({ index, item })}>
          <BaseCard
            imgUrl={item.imgUrl}
            title={item.title}
            contents={layoutType === WIDGET_LAYOUT_TYPE.BRAND.GRID._2x2 ? item.contents : undefined}
          />
        </Link>
      ))}
    </GridWidgetStyle>
  );
};

export default GridWidget;
