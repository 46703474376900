import styled from "@styles/themedComponents";

export const TimeDealWidgetStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 15px;
`;

export const TimeDealWidgetHeaderStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;

  strong {
    ${(props) => props.theme.adsTypo.headingL}
    color: ${(props) => props.theme.adsColor.black}
  }

  p {
    ${(props) => props.theme.adsTypo.detailM}
    color: ${(props) => props.theme.adsColor.gray_07}
  }
`;

export const TimeDealWidgetBodyStyle = styled.div``;

export const SliderItemWrapperStyle = styled.div`
  padding-bottom: 28px;
`;

export const TimeOverDimmedStyle = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.theme.adsTypo.headingL}
  color: ${(props) => props.theme.adsColor.white};
  background-color: #00000066;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
