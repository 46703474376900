import { DeviceSize } from "@styles/themedComponents";
import { BRAND_LIST_PAGE_URL_PATH, PRODUCT_LIST_PAGE_URL_PATH } from "../../url/internalUrlConstants";

// 브라우저 너비에 따른 메인 해더 높이
export const MAIN_HEADER_HEIGHT = {
  [DeviceSize.SSR]: 0,
  [DeviceSize.DESKTOP]: 73,
  [DeviceSize.TABLET]: 56,
  [DeviceSize.PHONE]: 56,
};

// 헤더 메인 메뉴 리스트
export const MAIN_HEADER_MENU = [
  {
    url: PRODUCT_LIST_PAGE_URL_PATH,
    name: "카테고리",
  },
  {
    url: BRAND_LIST_PAGE_URL_PATH,
    name: "브랜드",
  },
];
