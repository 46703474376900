import styled from "@styles/themedComponents";

interface IScrollWidgetStyleProps {
  margin: boolean;
}
export const ScrollWidgetStyle = styled.div<IScrollWidgetStyleProps>`
  article {
    margin-bottom: 20px;
  }

  figure {
    padding: ${({ margin }) => (margin ? "0 15px" : "0")};

    img {
      border-radius: ${({ margin }) => (margin ? "2px" : "0")};
    }
  }
  strong,
  p {
    padding: 0 15px;
  }
`;
