import styled from "@styles/themedComponents";

interface ISwipeWidgetStyleProps {
  size: "big" | "small";
  swipe: boolean;
  edge: boolean;
}
export const SwipeWidgetStyle = styled.div<ISwipeWidgetStyleProps>`
  padding: 0 15px;
  .swiper {
    overflow: visible;
    .swiper-wrapper {
      margin-left: 0;
  }
  .swiper-slide {
    width: ${({ size }) => (size === "big" ? "92.7%" : "46.3%")};
  }

  img {
    border-radius: 2px;
  }
    
`;
