import * as React from "react";
import { FC } from "react";
import Link from "next/link";

import { Card } from "@amondz/design-system";
import { PRODUCT_STATUS_V1 } from "@amondz/constants";
import Img from "@components/common/Img/Img";
import { ProductCardStateType } from "@store/modules/common/types";
import { ImageUrlStateType } from "@store/modules/home";
import { TODAY_DELIVERY_FILTER } from "@constants/service/common/common";
import { IMAGE_SIZE } from "@constants/service/common/imageSizeConstants";
import { PRODUCT_DETAIL_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import { SwipeWidgetStyle } from "./style";

interface ISwipeWidgetProps {
  topImage?: ImageUrlStateType;
  widgetOrEventDetailPageUrl: string;
  size?: "big" | "small";
  itemList: ProductCardStateType[];
  onClickLike: (pid: number, itemIndex: number) => void;
  onClickWidgetItem: (param: { index: number; item: ProductCardStateType }) => void;
}

const SwipeWidget: FC<ISwipeWidgetProps> = (props) => {
  const { itemList, topImage, widgetOrEventDetailPageUrl, size, onClickLike, onClickWidgetItem } = props;
  return (
    <SwipeWidgetStyle>
      {topImage && (
        <Link href={widgetOrEventDetailPageUrl}>
          <div className="widget-top-image">
            <Img
              className="product-top-img"
              src={topImage.url}
              width={topImage.width}
              height={topImage.height}
              layout="responsive"
            />
          </div>
        </Link>
      )}
      <div className="widget-product-wrapper">
        {itemList.map((item, index) => {
          return (
            <Link
              key={index}
              href={`${PRODUCT_DETAIL_PAGE_URL_PATH}/[id]`}
              as={`${PRODUCT_DETAIL_PAGE_URL_PATH}/${item.id}`}
            >
              <Card
                type="basic"
                className={`widget-product ${!size ? "" : size}`}
                image={
                  <Img
                    src={item.imgUrl}
                    width={IMAGE_SIZE.LARGE.WIDTH}
                    height={IMAGE_SIZE.LARGE.HEIGHT}
                    loading="eager"
                    layout="responsive"
                  />
                }
                productName={item.name}
                brandName={size === "big" ? item.storeName : undefined}
                status={item.status === PRODUCT_STATUS_V1.SOLD_OUT ? "soldOut" : "default"}
                salesPrice={item.salesPrice}
                discountRate={item.discountRate}
                isLike={size === "big" ? !!item.likeFlag : undefined}
                isTodayDelivery={size === "big" && item.isTodayDelivery === TODAY_DELIVERY_FILTER.TODAY_DELIVERY}
                onClickCard={() => onClickWidgetItem({ index, item })}
                onClickLike={() => onClickLike(item.id, index)}
              />
            </Link>
          );
        })}
      </div>
    </SwipeWidgetStyle>
  );
};

export default SwipeWidget;
