import * as React from "react";
import { useRouter } from "next/router";
import { WidgetType } from "@amondz/types";
import { WIDGET } from "@amondz/constants";
import WidgetList from "@views/home/homeList/WidgetList";
import { useSubHome } from "@views/home/homeList/SubHomeListWrapper/useSubHome";
import { STATUS_CODE_API } from "@constants/status/code/statusCodeApi";
import WidgetSelector from "@views/home/homeList/WidgetSelector";
import { EventWidgetWrapperStyle, SubHomeListWrapperStyle } from "./style";

export interface SubHomeListProps {
  id: number;
  fallback?: JSX.Element;
}

const SubHomeList = (props: SubHomeListProps): JSX.Element => {
  const { id, fallback } = props;
  const { homeListState, homeListStatus, updateWidget, isLoading } = useSubHome(id);
  const router = useRouter();

  if (isLoading) return fallback || <></>;
  if (
    homeListStatus === STATUS_CODE_API.HOME_SUB_LIST_FIRST.CODE_115010000 ||
    !homeListState ||
    homeListState?.widgetList.length === 0
  ) {
    router.push("/404");
    return <></>;
  }

  const [firstWidget, ...restWidget] = homeListState.widgetList;
  const isFirstEventWidget = firstWidget.widgetType === WIDGET.EVENT;

  return (
    <SubHomeListWrapperStyle isFirstEventWidget={isFirstEventWidget}>
      <EventWidgetWrapperStyle>
        <WidgetSelector
          index={0}
          widget={
            isFirstEventWidget
              ? ({ ...firstWidget, mainTitle: null, subTitle: null, buttonText: null } as WidgetType & {
                  mainTitle: null;
                })
              : firstWidget
          }
          onLikeProduct={updateWidget}
        />
      </EventWidgetWrapperStyle>
      <WidgetList widgetList={restWidget} onLikeProduct={updateWidget} isFirstWidgetExcluded={isFirstEventWidget} />
    </SubHomeListWrapperStyle>
  );
};

export default SubHomeList;
