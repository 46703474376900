import { includes } from "lodash";
import { BANNER_LINK_TYPE, BANNER_LINK_URL } from "@constants/service/home/home";

/**
 * linkType 에 맞는 url 가져오기
 * @param linkType linkType
 * @param linkUrl linkType 외부 url(1000)일 경우 사용. 그 외에는 직접적으로 사용 안함
 * @param id 상세페이지에서 사용. 배너 위젯에서는 linkUrl자체에 id정보가 포함되어 있는 경우, id가 null이 될 수 있음
 */
export const getUrlToLinkType = (linkType: number, linkUrl: string | null, id: number | null): string => {
  // 링크 타입이 외부 타입일 경우
  if (linkType === BANNER_LINK_TYPE.EXTERNAL_LINK && linkUrl) {
    return linkUrl;
  }

  let url = BANNER_LINK_URL[linkType];

  // 링크 타입이 카테고리 타입일 경우
  if (linkType === BANNER_LINK_TYPE.CATEGORY_LINK && linkUrl) {
    const urlObj = new URL(linkUrl);
    url += urlObj.search;
  }

  if (
    id !== null &&
    includes(
      [
        BANNER_LINK_TYPE.BRAND,
        BANNER_LINK_TYPE.EVENT,
        BANNER_LINK_TYPE.PRODUCT_DETAIL,
        BANNER_LINK_TYPE.REVIEW_DETAIL,
        BANNER_LINK_TYPE.HOME,
      ],
      linkType,
    )
  ) {
    url += `/${id}`;
  }
  return url;
};
