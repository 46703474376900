import * as React from "react";
import { PropsWithChildren } from "react";
import Head from "next/head";
import { PAGE_BASE_DESCRIPTION, AMONDZ_SLOGAN_TEXT, PAGE_BASE_NAME, PAGE_BASE_KEYWORDS } from "@amondz/constants-page";
import { AMONDZ_LOGO_OG_TAG_URL } from "@constants/url/externalUrlConstants";
import { OgType } from "@amondz/types";

interface BasePageProps {
  title?: string;
  url?: string;
  description?: string;
  ogDescription?: string;
  image?: string | null;
  canonical?: string | null;
  type?: OgType;
  altText?: string;
}

const BasePage = (props: PropsWithChildren<BasePageProps>) => {
  const {
    title = AMONDZ_SLOGAN_TEXT,
    url,
    description = PAGE_BASE_DESCRIPTION,
    ogDescription,
    image,
    canonical,
    children,
    type = "website",
    altText,
  } = props;

  let ogUrl = null;
  if (url) {
    // url props가 있을 경우 og:url 설정
    const amondzUserWebHostUrl = process.env.NEXT_PUBLIC_USER_WEB_HOST_URL;
    ogUrl = `${amondzUserWebHostUrl}${url}`;
  }

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        {ogUrl && <meta property="og:url" content={ogUrl} />}
        <meta property="og:description" content={ogDescription ? ogDescription : description} />
        <meta property="og:image" content={image || AMONDZ_LOGO_OG_TAG_URL} />
        {altText && <meta property="og:image:alt" content={altText} />}
        <meta name="description" content={description} />
        <meta name="og:type" content={type} />
        <meta name="og:name" content={PAGE_BASE_NAME} />
        <meta name="keywords" content={PAGE_BASE_KEYWORDS} />
        {canonical && <link rel="canonical" href={canonical} />}
      </Head>
      {children}
    </>
  );
};

export default BasePage;
