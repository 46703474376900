import styled, { createGlobalStyle, DeviceSize } from "@styles/themedComponents";
import { MAIN_HEADER_HEIGHT } from "@constants/service/base/header";

export const GlobalStyle = createGlobalStyle`
  main {
    background-color: ${(props) => props.theme.color.amondz_white};
  }
`;

export const HomeInnerLayoutStyle = styled.div`
  position: relative;
  min-height: calc(100vh - ${MAIN_HEADER_HEIGHT[DeviceSize.PHONE]}px);
  margin: 0 auto 80px;
  font-family: ${(props) => props.theme.fonts.notoSansCJKkr};
  color: ${(props) => props.theme.color.amondz_black};
`;
