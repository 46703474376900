import { produce } from "immer";
import { useQueryClient } from "@tanstack/react-query";
import { useSubHomeList, subHomeQueryKeys } from "@amondz/react-query";
import { Response, WidgetType, SubHomeListStateType } from "@amondz/types";

interface UseSubHomeReturnType {
  homeListState?: SubHomeListStateType;
  homeListStatus?: number;
  isLoading: boolean;
  isError: boolean;
  updateWidget: (widget: WidgetType) => void;
}

export const useSubHome = (id: number): UseSubHomeReturnType => {
  const queryClient = useQueryClient();
  const { data, isLoading, isError, isSuccess } = useSubHomeList(id);

  const updateWidget = (widget: WidgetType) => {
    queryClient.setQueryData(subHomeQueryKeys.list(id), (prev) =>
      produce(prev as Response<SubHomeListStateType>, (draft) => {
        if (draft?.data?.widgetList)
          draft.data.widgetList = draft.data.widgetList.map((item) => (item.id === widget.id ? widget : item));
      }),
    );
  };

  return {
    homeListStatus: data?.status,
    homeListState: data?.data,
    isLoading,
    isError: isError || (isSuccess && data?.status !== 1),
    updateWidget,
  };
};
