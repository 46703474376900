import styled from "@styles/themedComponents";

interface GridWidgetStyleProps {
  pagination: boolean;
}

export const GridWidgetStyle = styled.div<GridWidgetStyleProps>`
  padding-left: 15px;
  padding-right: 15px;

  .swiper {
    ${({ pagination }) => pagination && "padding-bottom: 28px"};
  }
`;
