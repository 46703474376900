import * as React from "react";
import { FC } from "react";
import Link from "next/link";

import { Card } from "@amondz/design-system";
import { PRODUCT_STATUS_V1 } from "@amondz/constants";
import Img from "@components/common/Img/Img";
import { ProductCardStateType } from "@store/modules/common/types";
import { TODAY_DELIVERY_FILTER } from "@constants/service/common/common";
import { IMAGE_SIZE } from "@constants/service/common/imageSizeConstants";
import { PRODUCT_DETAIL_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import Slider from "../../../../../../components/common/Slider";
import { GridWidgetStyle } from "./style";

interface IGridWidgetProps {
  row: number;
  col: number;
  itemList: ProductCardStateType[];
  onClickLike: (pid: number, itemIndex: number) => void;
  onClickWidgetItem: (param: { index: number; item: ProductCardStateType }) => void;
}

const GridWidget: FC<IGridWidgetProps> = (props) => {
  const { itemList, row, col, onClickLike, onClickWidgetItem } = props;

  return (
    <GridWidgetStyle pagination={itemList.length > row * col}>
      <Slider
        grid={{
          fill: "row",
          rows: row,
        }}
        slidesPerView={col}
        slidesPerGroup={col}
        pagination
      >
        {itemList.map((item, index) => {
          return (
            <Link
              key={item.id}
              href={`${PRODUCT_DETAIL_PAGE_URL_PATH}/[id]`}
              as={`${PRODUCT_DETAIL_PAGE_URL_PATH}/${item.id}`}
            >
              <Card
                type="basic"
                className="widget-product"
                image={
                  <Img
                    src={item.imgUrl}
                    width={IMAGE_SIZE.LARGE.WIDTH}
                    height={IMAGE_SIZE.LARGE.HEIGHT}
                    layout="responsive"
                  />
                }
                productName={item.name}
                brandName={item.storeName}
                status={item.status === PRODUCT_STATUS_V1.SOLD_OUT ? "soldOut" : "default"}
                salesPrice={item.salesPrice}
                discountRate={item.discountRate}
                likeCount={item.likeCount}
                isLike={!!item.likeFlag}
                isTodayDelivery={item.isTodayDelivery === TODAY_DELIVERY_FILTER.TODAY_DELIVERY}
                onClickCard={() => onClickWidgetItem({ index, item })}
                onClickLike={() => onClickLike(item.id, index)}
              />
            </Link>
          );
        })}
      </Slider>
    </GridWidgetStyle>
  );
};

export default GridWidget;
