import styled from "@styles/themedComponents";

interface ISubHomeListWrapperStyleProps {
  isFirstEventWidget: boolean;
}
export const SubHomeListWrapperStyle = styled.div<ISubHomeListWrapperStyleProps>`
  margin-top: ${({ isFirstEventWidget }) => (isFirstEventWidget ? "0" : "20px")};
  padding-bottom: 40px;
`;

export const EventWidgetWrapperStyle = styled.section`
  margin-bottom: 80px;
`;
