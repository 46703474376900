import * as React from "react";
import { FC, ReactNode, useMemo } from "react";
import { GTM_CONTENT_TYPE, GTM_EVENT, useGtmEventLogging } from "@amondz/gtm";
import { WidgetType } from "@amondz/types";
import { WIDGET, WIDGET_NAME } from "@amondz/constants";
import useClickWidgetItem from "@lib/hooks/useClickWidgetItem";
import Product from "../widget/Product";
import Banner from "../widget/Banner";
import Brand from "../widget/Brand";
import Event from "../widget/Event";
import TimeDeal from "../widget/TimeDeal";
import Video from "../widget/Video";

export type ClickWidgetItemParam = {
  index: number;
  id?: number | null;
  name: string | null;
};

export interface IWidgetSelectorProps {
  index: number;
  widget: WidgetType;
  onLikeProduct: (widget: WidgetType) => void;
}

const WidgetSelector: FC<IWidgetSelectorProps> = ({ index, widget, onLikeProduct }) => {
  const onClickWidgetItem = useClickWidgetItem();
  const { trigger } = useGtmEventLogging();

  const handleClickWidgetItem = (widgetItem: ClickWidgetItemParam) => {
    trigger(GTM_EVENT.CLICK_WIDGET_ITEM, {
      itemId: widget.widgetType !== WIDGET.VIDEO ? widgetItem.id : null,
      itemName: widgetItem.name || "",
      itemIndex: widgetItem.index + 1,
      groupId: widget.widgetType !== WIDGET.VIDEO ? widget.id : null,
      groupName: widget.mainTitle || "",
      groupIndex: index + 1,
      groupType: WIDGET_NAME[widget.widgetType],
      contentType: GTM_CONTENT_TYPE.WIDGET_ITEM,
    });

    if (widget.widgetType === WIDGET.VIDEO) return;
    onClickWidgetItem(widget.id);
  };

  /**
   * widgetType 별 맵핑
   */
  const selectedWidget = useMemo(() => {
    let widgetContent: ReactNode | undefined;
    switch (widget.widgetType) {
      case WIDGET.PRODUCT:
        widgetContent = (
          <Product
            widgetIndex={index}
            widgetItem={widget}
            onLikeProduct={onLikeProduct}
            onClickWidgetItem={handleClickWidgetItem}
          />
        );
        break;
      case WIDGET.BRAND:
        widgetContent = <Brand widgetIndex={index} widgetItem={widget} onClickWidgetItem={handleClickWidgetItem} />;
        break;
      case WIDGET.EVENT:
        widgetContent = <Event widgetIndex={index} widgetItem={widget} onClickWidgetItem={handleClickWidgetItem} />;
        break;
      case WIDGET.BANNER:
        widgetContent = <Banner widgetIndex={index} widgetItem={widget} onClickWidgetItem={handleClickWidgetItem} />;
        break;
      case WIDGET.TIME_DEAL:
        widgetContent = <TimeDeal widgetIndex={index} widgetItem={widget} onClickWidgetItem={handleClickWidgetItem} />;
        break;
      // todo: 모비두 변경 후 작업 예정
      // case WIDGET.VIDEO:
      //   widgetContent = <Video onClickWidgetItem={handleClickWidgetItem} />;
      //   break;
    }
    return widgetContent;
  }, [widget]);

  return <>{selectedWidget}</>;
};

export default WidgetSelector;
