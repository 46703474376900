import styled, { css } from "@styles/themedComponents";
import { ARROW_LEFT_ROUND_IMAGE_PATH, ARROW_RIGHT_ROUND_IMAGE_PATH } from "@constants/static/images";

const navigationStyle = css`
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 50%;
    transform: translate(0, -50%);
    background-size: cover;
    z-index: ${(props) => props.theme.zIndex.arrowButton};
    cursor: pointer;

    &.swiper-button-disabled {
      display: none;
    }
  }
  .swiper-button-prev {
    left: 8px;
    background-image: url(${ARROW_LEFT_ROUND_IMAGE_PATH});
  }
  .swiper-button-next {
    right: 8px;
    background-image: url(${ARROW_RIGHT_ROUND_IMAGE_PATH});
  }
`;

const paginationStyle = css`
  .swiper-pagination-bullets {
    bottom: 0;
  }
  .swiper-pagination-bullet {
    background: none;
    border: 1px solid ${(props) => props.theme.color.amondz_black};
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background-color: ${(props) => props.theme.color.amondz_black};
  }

  .swiper-pagination-fraction {
    bottom: 15px;
    right: 15px;
    left: auto;
    width: auto;
    padding: 5px 10px;
    background-color: ${(props) => props.theme.color.amondz_black};
    opacity: 0.65;
    border-radius: 13px;
    font-family: ${(props) => props.theme.fonts.barlow};
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    color: ${(props) => props.theme.color.amondz_white};

    .swiper-pagination-total {
      color: ${(props) => props.theme.color.gray_01};
    }
  }
`;

export const SliderStyle = styled.div`
  line-height: 0;

  ${navigationStyle}
  ${paginationStyle}
`;

export const CustomBarPaginationSliderStyle = css`
  .swiper-pagination {
    display: flex;

    .swiper-pagination-bullet {
      flex-grow: 1;
      width: auto;
      height: 2px;
      background-color: ${({ theme }) => theme.color.gray_06};
      border: 0;
      margin: 0;
      border-radius: 0;
    }

    .swiper-pagination-bullet-active {
      background-color: ${({ theme }) => theme.color.amondz_black};
    }
  }

  .swiper-wrapper {
    padding-bottom: 42px;
  }
`;
