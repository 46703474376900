import styled from "@styles/themedComponents";

export const WidgetListStyle = styled.section`
  overflow: hidden;
  & > article:not(:last-of-type) {
    margin-bottom: 60px;
  }
`;

export const WidgetCoverStyle = styled.article``;
