import * as React from "react";
import { FC } from "react";
import Link from "next/link";
import { BrandBaseWidgetItemType, BrandCommonWidgetItemType } from "@amondz/types";
import BaseCard from "@components/common/BaseCard";
import { BRAND_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import Slider from "../../../../../../components/common/Slider";
import { SwipeWidgetStyle } from "./style";

interface ISwipeWidgetProps {
  itemList: BrandCommonWidgetItemType[];
  onClickWidgetItem: <Item extends BrandBaseWidgetItemType>(param: { index: number; item: Item }) => void;
}

const SwipeWidget: FC<ISwipeWidgetProps> = (props) => {
  const { itemList, onClickWidgetItem } = props;
  const col = 1;

  return (
    <SwipeWidgetStyle>
      <Slider loop={itemList.length > 2} pagination={true} slidesPerView={col}>
        {itemList.map((item, index) => (
          <Link
            key={index}
            href={`${BRAND_PAGE_URL_PATH}/${item.id}`}
            onClick={() => onClickWidgetItem({ index, item })}
          >
            <BaseCard
              imgUrl={item.topImage.url}
              imgSize={{ width: item.topImage.width, height: item.topImage.height }}
              title={item.title}
              contents={item.contents}
            />
          </Link>
        ))}
      </Slider>
    </SwipeWidgetStyle>
  );
};

export default SwipeWidget;
