import * as React from "react";
import { FC } from "react";
import Link from "next/link";
import { BannerStateType } from "@amondz/types";
import { WIDGET_LAYOUT_TYPE } from "@amondz/constants";
import Img from "@components/common/Img";
import { getUrlToLinkType } from "@lib/utility/linkType";
import { MOBILE_EVENT_AND_BANNER_KEY } from "@constants/service/home/home";
import { INTRO_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import { GridWidgetStyle } from "./style";

interface IGridWidgetProps {
  itemList: BannerStateType[];
  imageTarget: typeof MOBILE_EVENT_AND_BANNER_KEY;
  layoutType: typeof WIDGET_LAYOUT_TYPE.BANNER.GRID_1X2 | typeof WIDGET_LAYOUT_TYPE.BANNER.GRID_2X2;
  onClickWidgetItem: (param: { index: number; item: BannerStateType }) => void;
}

const GridWidget: FC<IGridWidgetProps> = (props) => {
  const { itemList, imageTarget, layoutType, onClickWidgetItem } = props;

  const maxCount = {
    [WIDGET_LAYOUT_TYPE.BANNER.GRID_1X2]: 2,
    [WIDGET_LAYOUT_TYPE.BANNER.GRID_2X2]: 4,
  };

  return (
    <GridWidgetStyle>
      {itemList.slice(0, maxCount[layoutType]).map((item, index) => (
        <Link
          key={index}
          href={getUrlToLinkType(item.linkType, item.linkUrl, item.id) || INTRO_PAGE_URL_PATH}
          onClick={() => onClickWidgetItem({ index, item })}
        >
          <Img
            src={item[imageTarget]?.imgUrl}
            width={item[imageTarget]?.width}
            height={item[imageTarget]?.height}
            layout="responsive"
          />
        </Link>
      ))}
    </GridWidgetStyle>
  );
};

export default GridWidget;
