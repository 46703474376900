import * as React from "react";
import { FC, ReactNode, useMemo } from "react";
import { Autoplay, EffectFade, Grid, Navigation, Pagination, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  AutoplayOptions,
  GridOptions,
  NavigationOptions,
  PaginationOptions,
  Swiper as SwiperClass,
  ThumbsOptions,
} from "swiper/types";

import { SliderStyle } from "./style";

export interface ISliderProps {
  autoplay?: boolean | AutoplayOptions;
  centeredSlides?: boolean;
  centerInsufficientSlides?: boolean;
  className?: string;
  effect?: "slide" | "fade" | "cube" | "coverflow" | "flip" | "creative" | "cards";
  grid?: GridOptions;
  initialSlide?: number;
  loop?: boolean;
  loopFillGroupWithBlank?: boolean;
  navigation?: boolean | NavigationOptions;
  pagination?:
    | boolean
    | (
        | PaginationOptions
        | {
            type: "fraction";
            formatFractionCurrent: (num: number) => string | number;
            formatFractionTotal: (num: number) => string | number;
          }
      );
  slidesPerView?: number | "auto";
  slidesPerGroup?: number;
  spaceBetween?: number;
  thumbs?: ThumbsOptions;
  onClick?: (swiper: SwiperClass, event: MouseEvent | TouchEvent | PointerEvent) => void;
  onSlideChange?: (activeIndex: number, prevIndex: number) => void;
  onSwiper?: (swiper: SwiperClass) => void;
  children: ReactNode[];
}

const Slider: FC<ISliderProps> = (props) => {
  const {
    autoplay = false,
    centeredSlides,
    centerInsufficientSlides,
    className,
    effect,
    grid,
    initialSlide = 0,
    loop,
    loopFillGroupWithBlank,
    navigation = false,
    pagination = false,
    slidesPerView = 1,
    slidesPerGroup = 1,
    spaceBetween = 15,
    thumbs,
    onClick,
    onSlideChange,
    onSwiper,
    children,
  } = props;

  const paginationValue = useMemo(() => {
    return pagination === true
      ? {
          clickable: true,
        }
      : pagination;
  }, [pagination]);

  const handleSlideChange = (swiper: SwiperClass) => {
    if (!onSlideChange) return;

    onSlideChange(swiper.activeIndex, swiper.previousIndex);
  };

  const handleClick = (swiper: SwiperClass, event: MouseEvent | TouchEvent | PointerEvent) => {
    event.preventDefault();

    if (!onClick) return;

    onClick(swiper, event);
  };

  return (
    <SliderStyle className={className}>
      <Swiper
        autoplay={autoplay}
        centeredSlides={centeredSlides}
        centerInsufficientSlides={centerInsufficientSlides}
        effect={effect}
        grid={grid}
        //TODO: initialSlide props 명을 initialSlideIndex or initialIndex 등 구체적인 명칭으로 수정하기
        initialSlide={initialSlide}
        loop={loop}
        loopFillGroupWithBlank={loopFillGroupWithBlank}
        navigation={navigation}
        // formatFractionIndex 타입을 만족시키기 위해 임의로 타입 강제화
        pagination={paginationValue as boolean | PaginationOptions | undefined}
        slidesPerView={slidesPerView}
        slidesPerGroup={slidesPerGroup}
        spaceBetween={spaceBetween}
        thumbs={thumbs}
        modules={[Autoplay, Navigation, Pagination, Grid, Thumbs, EffectFade]}
        onClick={handleClick}
        onSlideChange={handleSlideChange}
        onSwiper={onSwiper}
      >
        {children.map((slide, index) => (
          <SwiperSlide key={index}>{slide}</SwiperSlide>
        ))}
      </Swiper>
    </SliderStyle>
  );
};

export const formatFractionIndex = (num: number) => (num < 10 ? `0${num}` : num);

export default Slider;
