import styled from "@styles/themedComponents";

export const MainEventWidgetStyle = styled.div`
  position: relative;
  right: calc(50vw - 50%);
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;

  > div {
    width: 100%;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  .swiper-pagination-lock {
    display: block;
  }

  .swiper-pagination-fraction {
    right: 15px;
  }
`;
