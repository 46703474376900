import styled from "@styles/themedComponents";

export const WidgetHeaderStyle = styled.div`
  margin-bottom: 15px;
  text-align: center;

  strong {
    font-size: 18px;
    font-weight: bold;
    line-height: 27px;
    letter-spacing: -0.5px;
  }

  p {
    margin-top: 2px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.6px;
    color: ${(props) => props.theme.color.gray_01};
  }
`;