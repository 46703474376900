import Link from "next/link";
import styled from "@styles/themedComponents";

export const ScrollWidgetStyle = styled.div`
  > a:not(:first-child) {
    margin-top: 20px;
  }
`;

export const ScrollWidgetItemStyle = styled(Link)`
  display: flex;
  flex-direction: column;

  article {
    figure {
      img {
        border-radius: 0;
      }
    }
    strong,
    p {
      padding: 0 15px;
    }
  }
`;
