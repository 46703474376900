import * as React from "react";
import { FC } from "react";
import Link from "next/link";
import { BrandBaseWidgetItemType, BrandCommonWidgetItemType } from "@amondz/types";
import { WIDGET_LAYOUT_TYPE } from "@amondz/constants";
import BaseCard from "@components/common/BaseCard";
import { BRAND_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import { ScrollWidgetStyle } from "./style";

interface IScrollWidgetProps {
  itemList: BrandCommonWidgetItemType[];
  layoutType: number;
  onClickWidgetItem: <Item extends BrandBaseWidgetItemType>(param: { index: number; item: Item }) => void;
}

const ScrollWidget: FC<IScrollWidgetProps> = (props) => {
  const { itemList, layoutType, onClickWidgetItem } = props;

  return (
    <ScrollWidgetStyle margin={layoutType === WIDGET_LAYOUT_TYPE.BRAND.SCROLL.MARGIN_YES}>
      {itemList.map((item, index) => (
        <Link key={index} href={`${BRAND_PAGE_URL_PATH}/${item.id}`} onClick={() => onClickWidgetItem({ index, item })}>
          <BaseCard
            imgUrl={item.topImage.url}
            imgSize={{ width: item.topImage.width, height: item.topImage.height }}
            title={item.title}
            contents={item.contents}
          />
        </Link>
      ))}
    </ScrollWidgetStyle>
  );
};

export default ScrollWidget;
