import * as React from "react";
import { EventStateType, EventWidgetType } from "@amondz/types";
import { WIDGET } from "@amondz/constants";
import { ClickWidgetItemParam } from "@views/home/homeList/WidgetSelector/WidgetSelector";
import MainEventWidget from "./MainEventWidget";
import WidgetHeader from "@views/home/homeList/widget/common/WidgetHeader";
import WidgetFooter from "@views/home/homeList/widget/common/WidgetFooter";

export interface IEventProps {
  className?: string;
  widgetIndex: number;
  widgetItem: EventWidgetType;
  onClickWidgetItem: (widgetItem: ClickWidgetItemParam) => void;
}

const Event = (props: IEventProps) => {
  const { className, widgetIndex, widgetItem, onClickWidgetItem } = props;

  const handleClickWidgetItem = ({ index, item }: { index: number; item: EventStateType }) => {
    onClickWidgetItem({
      index,
      id: item.id,
      name: null,
    });
  };

  if (widgetItem.itemList.length === 0 || widgetItem.widgetType !== WIDGET.EVENT) return null;

  return (
    <div>
      <WidgetHeader title={widgetItem.mainTitle} subTitle={widgetItem.subTitle} />
      <MainEventWidget className={className} itemList={widgetItem.itemList} onClickWidgetItem={handleClickWidgetItem} />
      <WidgetFooter widgetIndex={widgetIndex} widget={widgetItem} />
    </div>
  );
};

export default Event;
