import React from "react";
import { useCallback } from "react";
import { BrandBaseWidgetItemType } from "@amondz/types";
import { WIDGET, WIDGET_LAYOUT_TYPE } from "@amondz/constants";
import WidgetHeader from "@views/home/homeList/widget/common/WidgetHeader";
import WidgetFooter from "@views/home/homeList/widget/common/WidgetFooter";
import GridWidget from "./GridWidget";
import SwipeWidget from "./SwipeWidget";
import ScrollWidget from "./ScrollWidget";
import { IBrandProps } from "./types";

const Brand = (props: IBrandProps): React.JSX.Element | null => {
  const { widgetIndex, widgetItem, onClickWidgetItem } = props;

  const handleClickWidgetItem = ({ index, item }: { index: number; item: BrandBaseWidgetItemType }): void => {
    onClickWidgetItem({
      index,
      id: item.id,
      name: item.title,
    });
  };

  /**
   * layoutType 별 맵핑
   */
  const mappingLayoutType = useCallback(() => {
    if (widgetItem.widgetType !== WIDGET.BRAND) return;

    const layoutTypes = WIDGET_LAYOUT_TYPE.BRAND;
    if (widgetItem.layoutType === layoutTypes.GRID._2x2 || widgetItem.layoutType === layoutTypes.GRID._1x4) {
      return (
        <GridWidget
          key={widgetItem.id}
          itemList={widgetItem.itemList}
          layoutType={widgetItem.layoutType}
          onClickWidgetItem={handleClickWidgetItem}
        />
      );
    } else if (
      widgetItem.layoutType === layoutTypes.SCROLL.MARGIN_YES ||
      widgetItem.layoutType === layoutTypes.SCROLL.MARGIN_NO
    ) {
      return (
        <ScrollWidget
          key={widgetItem.id}
          itemList={widgetItem.itemList}
          layoutType={widgetItem.layoutType}
          onClickWidgetItem={handleClickWidgetItem}
        />
      );
    } else if (widgetItem.layoutType === layoutTypes.SWIPE) {
      return (
        <SwipeWidget key={widgetItem.id} itemList={widgetItem.itemList} onClickWidgetItem={handleClickWidgetItem} />
      );
    }
  }, [widgetItem]);

  if (widgetItem.itemList.length === 0) return null;

  return (
    <div>
      <WidgetHeader title={widgetItem.mainTitle} subTitle={widgetItem.subTitle} />
      {mappingLayoutType()}
      <WidgetFooter widgetIndex={widgetIndex} widget={widgetItem} />
    </div>
  );
};

export default Brand;
