import styled from "@styles/themedComponents";
import Button from "@components/common/Button";

export const MoreBtnStyle = styled(Button)`
  width: 100%;
  height: 44px;
  font-size: 13px;
  line-height: 44px;
  border: 1px solid ${(props) => props.theme.color.amondz_black};
`;
