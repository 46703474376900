import * as React from "react";
import { FC } from "react";
import Link from "next/link";
import { BannerStateType } from "@amondz/types";
import Img from "@components/common/Img";
import { getUrlToLinkType } from "@lib/utility/linkType";
import { MOBILE_EVENT_AND_BANNER_KEY, PC_EVENT_AND_BANNER_KEY } from "@constants/service/home/home";
import { INTRO_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import Slider from "../../../../../../components/common/Slider";
import { IndicatorWidgetStyle } from "./style";

interface IIndicatorWidgetProps {
  itemList: BannerStateType[];
  imageTarget: typeof PC_EVENT_AND_BANNER_KEY | typeof MOBILE_EVENT_AND_BANNER_KEY;
  layoutType: number;
  onClickWidgetItem: (param: { index: number; item: BannerStateType }) => void;
}

const IndicatorWidget: FC<IIndicatorWidgetProps> = (props) => {
  const { itemList, imageTarget, layoutType, onClickWidgetItem } = props;

  return (
    <IndicatorWidgetStyle margin={!(layoutType % 2)}>
      <Slider loop={itemList.length > 1} pagination spaceBetween={0}>
        {itemList.map((item, index) => {
          return (
            <Link
              key={index}
              href={getUrlToLinkType(item.linkType, item.linkUrl, item.id) || INTRO_PAGE_URL_PATH}
              onClick={() => onClickWidgetItem({ index, item })}
            >
              <Img
                src={item[imageTarget]?.imgUrl}
                width={item[imageTarget]?.width}
                height={item[imageTarget]?.height}
                layout="responsive"
              />
            </Link>
          );
        })}
      </Slider>
    </IndicatorWidgetStyle>
  );
};

export default IndicatorWidget;
